<template>
    <a-modal
            :visible="recordShow"
            width="950px"
            :maskClosable="false"
            @cancel="toggle(false)"
            :title=getTitleName()
            @ok="handleOk(false)"
            :destroyOnClose = destroyOnClose
    >
        <table  border="1">
            <thead>
                <tr style="text-align: center">
                    <th style="width: auto">考试科目</th>
                    <th style="width: 70px">考试方式</th>
                    <th style="width: auto">考试时间</th>
                    <th style="width: 200px">考点</th>
                    <th style="width: auto">考点地址</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item,index) in dataSource " :key="index" style="text-align: center">
                    <td>{{item.subjectName}}</td>
                    <td>{{getExamMethodName(item.examMethod)}}</td>
                    <td >{{item.examStime }} ~ {{item.examEtime}}</td>
                    <td >
                        <a-select @change="setCentreAddress($event.key,item)"
                                  style="width: 80%"
                                  label-in-value
                                  :default-value="{ key: getDefaultValue(item)}"
                        >
                            <a-select-option value="0">--请选择考点--</a-select-option>
                            <a-select-option
                                    v-for="(centre,index) in item.centreList"
                                    :key="index"
                                    :value="centre.centreId"
                                    :disabled = centre.disabled>
                                {{centre.centreName}}
                            </a-select-option>
                            <a-select-option value="-1">--不参加此考试--</a-select-option>
                        </a-select>
                    </td>
                    <td>{{item.centreAddress}}</td>
                </tr>
            </tbody>
        </table>
    </a-modal>
</template>


<script>
import api from '@/api'
export default {
  name: 'determine_centre',
  data() {
    return {
      columns: [
        {
          title: '考试科目',
          align: 'center',
          dataIndex: 'subjectName'
        },
        {
          title: '考试方式',
          align: 'center',
          customRender: (value, row, index) => {
              switch (Number(row.examMethod)) {
                  case 1:
                      return "中央形考"
                  case 2:
                      return "笔试"
                  case 3:
                      return "机考"
                  case 4:
                      return "易考通"
                  case 5:
                      return "大作业"
                  case 6:
                      return "分校形考"
                  case 7:
                      return "论文"
                  case 8:
                      return "学位英语"
                  case 9:
                      return "学分替代"
                  case 10:
                      return "待定"
                  case 11:
                      return "手机考试"
                  case 12:
                      return "口试"
                  case 13:
                      return "刷脸机考"
                  default:
                      return ''
              }
          }
        },
        {
          title: '考试时间',
          align: 'center',
          customRender: (value, row, index) => {
              return row.examStime + "~" + row.examEtime
          }
        },
        {
          title: '考点',
          align: 'center',
          dataIndex: 'centreName',
            customRender: (value, row, index) => {
                const cent = this.centreList
                return ' '
            }
        },
        {
          title: '考点地址',
          align: 'center',
          customRender: (value, row, index) => {
              return "";
          }
        }
      ],
      dataSource:[],
      dataSourceCopy:[],
      recordShow: false,
      destroyOnClose:true,
      disabledList:{},
      examMehodArray:[],
      disabledRow:{},
      titleName : ""
    }
  },
  mounted() {

  },
  computed:{

  },
  methods: {
      getTitleName(){
        return this.titleName;
      },
      open(dataSource ) {
          if (dataSource) {
              this.titleName ="确认考点"
              this.dataSource = JSON.parse(JSON.stringify(dataSource));
              this.dataSourceCopy = dataSource;
              this.initExamMehodArray();
              this.initDisabledList();
              this.addDisabledProperty()
              this.toggle()
          }
      },
      changeCentre(dataSource){
          if (dataSource) {
              this.titleName = '变更考点'
              this.dataSource = JSON.parse(JSON.stringify(dataSource));
              this.dataSourceCopy = dataSource;
              this.initExamMehodArray();
              this.toggle()
          }
      },
      toggle(flag = true) {
          this.recordShow = flag
      },
      async handleOk(flag = false) {
          for(let examMethod of this.examMehodArray){
              let filterArray = this.dataSource.filter((n)=>{
                  return n.examMethod == examMethod &&n.centreId !=null&& n.centreId !=''&& n.centreId !=undefined;
              })
              if(filterArray.length>0){
                  let tempCentreId = filterArray[0].centreId;
                  let tempArray = filterArray.filter((n)=>{
                      return tempCentreId != n.centreId;
                  })
                  if(tempArray.length>0){
                      this.$message.error('同一类型的考试必须选择相同的考点')
                      return ;
                  }
              }
          }
          let saveArray = this.dataSource.filter((n)=>{
              if(n.isUpdate){
                  //有变化数据就修改
                  for(let data of this.dataSourceCopy){
                      if(data.appointmentId == n.appointmentId&&
                          ( (data.centreId==undefined? null :data.centreId) !=n.centreId ||
                              (data.confirmStatus==undefined? 2:data.confirmStatus) != n.confirmStatus) ){
                          return n;
                      }
                  }

              }

          })
          if(saveArray.length>0){
              await api.student.updataAppointment(saveArray)
              await this.$emit('getAppointment')
          }
          this.recordShow = flag
      },
      getExamMethodName(examMethod){
          switch (Number(examMethod)) {
              case 1:
                  return "中央形考"
              case 2:
                  return "笔试"
              case 3:
                  return "机考"
              case 4:
                  return "易考通"
              case 5:
                  return "大作业"
              case 6:
                  return "分校形考"
              case 7:
                  return "论文"
              case 8:
                  return "学位英语"
              case 9:
                  return "学分替代"
              case 10:
                  return "待定"
              case 11:
                  return "手机考试"
              case 12:
                  return "口试"
              case 13:
                  return "刷脸机考"
              default:
                  return ''
          }
      },
      setCentreAddress(centreId,item){
          if(0==centreId){
              this.setRowProperty(item,null,null,2,true)
          }else if(-1==centreId){
              this.setRowProperty(item,null,null,3,true)
          }else{
              let newArray  = item.centreList.filter(function (n) {
                  return n.centreId ==  centreId
              })
              this.setRowProperty(item,newArray[0].centreAddress,centreId,1,true)
          }
          this.initDisabledList();
          this.addDisabledProperty()
      },
      setRowProperty(item,centreAddress,centreId,confirmStatus,isUpdate){
          this.$set(item,"centreAddress",centreAddress);
          this.$set(item,"centreId",centreId);
          this.$set(item,"confirmStatus",confirmStatus);
          this.$set(item,"isUpdate",isUpdate);
      }
      ,getDefaultValue(item){
          switch (Number(item.confirmStatus)) {
              case 1:
                  return item.centreId
              case 2:
                  return "0"
              case 3:
                  return "-1"
              default:
                  return '0'
          }
      }
      //考试方式去重
      ,initExamMehodArray(){
          let tempArray=[];
          for(let data of this.dataSource ){
              tempArray.push(data.examMethod);
          }
          this.examMehodArray = [...new Set(tempArray)]
      }

      ,initDisabledList(){
          this.disabledList = {};
          this.disabledRow = {};
          if(this.titleName=='确认考点'){
              for(let examMethod of this.examMehodArray){
                  for(let data of this.dataSource ){
                      if(data.examMethod == examMethod&&(data.centreId!=null && data.centreId != undefined)){
                          //保存每种考试类型的第一个符合条件的考点id
                          this.disabledList[[examMethod]] = data.centreId;
                          //记录该考试类型第一次选择的行数据，如果其他行数据没有选择考点前，该行可以重新选其他的考点
                          this.disabledRow[[examMethod]] = data.appointmentId;
                          break;
                      }
                  }
              }
          }else {
              for(let examMethod of this.examMehodArray){
                  for(let data of this.dataSource ){
                      //如果是变更考点需要加上isUpdate条件。
                      if(data.examMethod == examMethod&&(data.centreId!=null && data.centreId != undefined)&&data.isUpdate){
                          //保存每种考试类型的第一个符合条件的考点id
                          this.disabledList[[examMethod]] = data.centreId;
                          //记录该考试类型第一次选择的行数据，如果其他行数据没有选择考点前，该行可以重新选其他的考点
                          this.disabledRow[[examMethod]] = data.appointmentId;
                          break;
                      }
                  }
              }
          }

      }
      //循环遍历每条数据的考点，添加disabled属性
      ,addDisabledProperty(){
          for(let examMethod of this.examMehodArray){
              let centreId = this.disabledList[examMethod];
              if(centreId == undefined){
                  for(let data of this.dataSource ){
                      if(data.examMethod == examMethod){
                          for (let centreObj of data.centreList){
                               this.$set(centreObj,"disabled",false);
                          }
                      }
                  }
              }else{
                  for(let data of this.dataSource ){
                      if(data.examMethod == examMethod ){
                          if(!this.getCountByexamMethod(examMethod,data)){
                              for (let centreObj of data.centreList){
                                  if(centreId !=centreObj.centreId){
                                      this.$set(centreObj,"disabled",true);
                                  }else{
                                      this.$set(centreObj,"disabled",false);
                                  }
                              }
                          }else{
                              for (let centreObj of data.centreList){
                                  this.$set(centreObj,"disabled",false);
                              }
                          }
                      }
                  }
              }
          }
      }
      //如果同一类型的考试，已选择的相同考点>1,this.disabledRow[[examMethod]]的考点不能重新选择其他考点
      ,getCountByexamMethod (examMethod,data) {
          if(this.disabledRow[examMethod] == data.appointmentId ){
                let newArray = this.dataSource.filter((n)=>{
                    if(n.centreId !=null&& n.centreId!=undefined && n.appointmentId !=data.appointmentId&&n.examMethod ==examMethod){
                        if(this.titleName=='确认考点'){
                            return n;
                            //如果是变更考点需要加上n.isUpdate条件。
                        }else{
                            if(n.isUpdate){
                                return n;
                            }
                        }

                    }
                })

              return newArray.length>0?false:true;
          }else{
              return false;
          }

      }
  }
}
</script>
