<!--
 * @Author: xiaziwen
 * @Date: 2021-01-09 11:49:02
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-09 11:53:59
 * @FilePath: \crm-education\src\views\student\my_grade\c\MyGradeScoreTable.vue
-->
<template>
  <a-table
    class="cus-table fs-14"
    bordered
    :data-source="dataSource"
    :columns="columns"
    :pagination="false"
    rowKey="name1"
  ></a-table>
</template>

<script>
export default {
  name: 'examination_table',
  props: {
    dataSource: {
      required: false,
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      columns: [
        {
          title: '试卷号',
          align: 'center',
          dataIndex: 'examNo'
        },
        {
          title: '考试科目',
          align: 'center',
          dataIndex: 'subjectName'
        },
        {
          title: '考试方式',
          align: 'center',
          customRender: (value, row, index) => {
              switch (Number(row.examMethod)) {
                  case 2:
                      return "笔试"
                  case 3:
                      return "机考"
                  case 7:
                      return "论文"
                  case 12:
                    return "口试"
                  case 13:
                      return "刷脸机考"
                  default:
                      return ''
              }
          }
        },
        {
          title: '考试形式',
          align: 'center',
          customRender: (value, row, index) => {
              switch (Number(row.openType)) {
                  case 1:
                      return "开卷"
                  case 2:
                      return "闭卷"
                  default:
                      return '--'
              }
          }
        },
        {
          title: '考试时间',
          align: 'center',
            customRender: (value, row, index) => {
                return row.examStime + '~' + row.examEtime
            }
        },
        {
            title: '考场',
            align: 'center',
            dataIndex: 'roomName'
        },
        {
            title: '座位号',
            align: 'center',
            dataIndex: 'seatNo'
        },
        {
            title: '是否连考',
            align: 'center',
            customRender: (value, row, index) => {
                switch (Number(row.continuityState)) {
                    case 1:
                        return "否"
                    case 2:
                        return "是"
                    default:
                        return ''
                }
            }
        }
      ]
    }
  }
}
</script>
