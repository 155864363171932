
<template>
    <div>
      <a-table
              class="cus-table fs-14"
              bordered
              :data-source="dataSource"
              :columns="columns"
              :pagination="false"
              rowKey="status"
      ></a-table>
      <DetermineCentreDialog ref="DetermineCentreDialog" @getAppointment="getAppointment"></DetermineCentreDialog>
    </div>
</template>

<script>
import DetermineCentreDialog from './DetermineCentre'
import api from '@/api'
export default {
  name: 'select_centre',
  components: { DetermineCentreDialog  },
  props: {
    dataSource: {
      required: false,
      type: Array,
      default: () => []
    },
    examPlanInfo: {
      required: false,
      ype: Array,
      default: () => []
    }
  },
  data() {
    return {
      columns: [
        {
          title: '考试科目',
          align: 'center',
          dataIndex: 'subjectName'
        },
        {
          title: '考试方式',
          align: 'center',
          customRender: (value, row, index) => {
              switch (Number(row.examMethod)) {
                  case 1:
                      return "中央形考"
                  case 2:
                      return "笔试"
                  case 3:
                      return "机考"
                  case 4:
                      return "易考通"
                  case 5:
                      return "大作业"
                  case 6:
                      return "分校形考"
                  case 7:
                      return "论文"
                  case 8:
                      return "学位英语"
                  case 9:
                      return "学分替代"
                  case 10:
                      return "待定"
                  case 11:
                      return "手机考试"
                  case 12:
                      return "口试"
                  case 13:
                      return "刷脸机考"
                  default:
                      return ''
              }
          }
        },
        {
          title: '考试时间',
          align: 'center',
          customRender: (value, row, index) => {
              return row.examStime + "~" + row.examEtime
          }
        },
        {
          title: '考点',
          align: 'center',
          dataIndex: 'centreName',
            customRender: (value, row, index) => {
                if (row.centreName){
                    return row.centreName
                }
                return '--'
            }
        },
        {
          title: '确认状态',
          align: 'center',
          customRender: (value, row, index) => {
              switch (Number(row.confirmStatus)) {
                  case 1:
                      return "考点已确认"
                  case 2:
                      return "考点未确认"
                  case 3:
                      return "确认不参加"
                  default:
                      return '考点未确认'
              }
          }
        },
        {
          title: '操作',
          align: 'center',
          customRender: (value, row, index) => {
            const selectCentre =()=>{
                this.selectCentre(row);
            }
            const changeCentre =()=>{
                this.changeCentre(row);
            }
            if(row.examMethod == 2|| row.examMethod==3 || row.examMethod==12 || row.examMethod == 13){
                if(row.confirmStatus==2 || row.confirmStatus==undefined){
                    return (<a-button class="cus-button" size="small" type="primary" onClick={ selectCentre }>确认考点</a-button>)
                }else {
                    return (<a-button class="cus-button" size="small" type="primary" onClick={ changeCentre }>变更考点</a-button>)
                }

            }else{
                if(row.confirmStatus==2 || row.confirmStatus==undefined){
                    return (<a-button  size="small" type="primary" disabled>确认考点</a-button>)
                }else {
                    return (<a-button  size="small" type="primary" disabled>变更考点</a-button>)
                }

            }

          }
        }
      ],
    }
  },
    mounted() {

    },
  methods: {
      /*进入打印准考证页面*/
      async selectCentre(row) {
          this.$refs.DetermineCentreDialog.open(this.dataSource ||[])
      },
      async changeCentre(row) {
          this.$refs.DetermineCentreDialog.changeCentre(this.dataSource.filter(n=>{
              return n.examMethod==row.examMethod
          }) ||[])
      },
      getAppointment(){
          this.$emit("getAppointment")
      }

  }



}
</script>
