<!--
 * @Author: xiaziwen
 * @Date: 2021-01-09 11:05:41
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-09 11:12:46
 * @FilePath: \crm-education\src\views\student\my_exam\c\MyExamTimeTable.vue
-->
<template>
    <div>
      <a-table
              class="cus-table fs-14"
              bordered
              :data-source="dataSource"
              :columns="columns"
              :pagination="false"
              rowKey="status"
      ></a-table>
      <coursework-user-dialog ref="courseworkUserDialog" ></coursework-user-dialog>
      <print-ticket-dialog ref="printTicketDialog"></print-ticket-dialog>
    </div>
</template>

<script>
import CourseworkUserDialog from './CourseworkUser'
import PrintTicketDialog from './PrintTicket'
import api from '@/api'
export default {
  name: 'my_exam_time_table',
  components: {CourseworkUserDialog, PrintTicketDialog  },
  props: {
    dataSource: {
      required: false,
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      columns: [
        {
          title: '考试科目',
          align: 'center',
          dataIndex: 'subjectName'
        },
        {
          title: '考试方式',
          align: 'center',
          customRender: (value, row, index) => {
              switch (Number(row.examMethod)) {
                  case 1:
                      return "中央形考"
                  case 2:
                      return "笔试"
                  case 3:
                      return "机考"
                  case 4:
                      return "易考通"
                  case 5:
                      return "大作业"
                  case 6:
                      return "分校形考"
                  case 7:
                      return "论文"
                  case 8:
                      return "学位英语"
                  case 9:
                      return "学分替代"
                  case 10:
                      return "待定"
                  case 11:
                      return "手机考试"
                  case 12:
                      return "口试"
                  case 13:
                      return "刷脸机考"
                  default:
                      return ''
              }
          }
        },
        {
          title: '考试时间',
          align: 'center',
          customRender: (value, row, index) => {
              return row.examStime + "~" + row.examEtime
          }
        },
        {
          title: '考试成绩',
          align: 'center',
          dataIndex: 'examScore',
            customRender: (value, row, index) => {
                if (row.examScore){
                    return row.examScore
                }
                return '--'
            }
        },
        {
          title: '状态',
          align: 'center',
          customRender: (value, row, index) => {
              switch (Number(row.examState)) {
                  case 1:
                      return "待考试"
                  case 2:
                      return "考试中"
                  case 3:
                      return "已结束"
                  default:
                      return ''
              }
          }
        },
        {
          title: '操作',
          align: 'center',
          customRender: (value, row, index) => {
            const review =()=>{
                this.reviewRecord(row);  //调用method里面的方法
            }
            const print =()=>{
                this.printcard(row);
            }
            const link =()=>{
                this.linkUrl(row);
            }
            const mystudy =()=>{
              this.goMystudy(row);
            }
            const mygraduat =()=>{
              this.goMygraduat(row);
            }
            const yktLink =()=>{
                this.goYktLinkUrl(row);
            }
            if(row.examMethod == '1' )
                return (<a-button class="cus-button" size="small" type="primary" onClick={ mystudy }>进入考试</a-button>)
            else if (row.examMethod == '7' )
                    return (<a-button class="cus-button" size="small" type="primary" onClick={ mygraduat }>进入考试</a-button>)
            else if(row.examMethod == '2' || row.examMethod == '3' || row.examMethod == '12' || row.examMethod == '13'){
                if(row.isArrangement==1){
                    return (<a-button  size="small" type="primary" onClick={ print } disabled >打印通知单</a-button>)
                }
                  return (<a-button class="cus-button" size="small" type="primary" onClick={ print }>打印通知单</a-button>)
              }
            else if(row.examMethod == '5' && row.workUserExamState != '0')
                    return (<a-button class="cus-button" size="small" type="primary" onClick={ review }>进入大作业</a-button>)
            else if(row.examMethod == '5' && row.workUserExamState == '0')
                    return (<a-button class="cus-button" size="small" type="primary" onClick={ review }>请重新提交</a-button>)
            else if(row.examMethod == '6' )
                if (row.examState == '3' && row.examScore) {
                    return (<a-button class="cus-button" type="small" type="primary" onClick={ link }>查看</a-button>)
                } else {
                    return (<a-button class="cus-button" type="small" type="primary" onClick={ link }>进入考试</a-button>)
                }
            else if (row.examMethod == '4') {
                  return (<a-button class="cus-button" size="small" type="primary" onClick={ yktLink }>进入考试</a-button>)
              }
            else  return "无"
          }
        }
      ],
        linkBasrUrl: ''
    }
  },
    mounted() {

    },
  methods: {
      /*进入大作业页面*/
      async reviewRecord(row) {
        if(row.examState == '1'){
            this.$message.info('当前未到考试时间')
            return ;
        }
        if(row.examState == '3'){
            this.$message.info('考试已结束')
            return ;
        }
        this.subjectId = row.subjectId
        const { data = {} } = await api.student.courseworkDetail({ subjectId: this.subjectId })
        this.$refs.courseworkUserDialog.open(data || {})
      },
      /*进入打印准考证页面*/
      async printcard(row) {
          if (row.isOuchnPrint == 'Y') {
              let url = "http://one.ouchn.cn/";
              window.open(url, '_blank');
          } else {
              const { data = {} } = await api.student.examTicketInfo({ planId: row.planId, examMethod:row.examMethod})
              this.$refs.printTicketDialog.open(data || {})
          }
      },
      /*进入分校形考页面*/
      async linkUrl(row) {
          if(row.examState == '1'){
              this.$message.info('当前未到考试时间')
              return ;
          }
          if(row.examState == '3' && !row.examScore){
              this.$message.info('考试已结束')
              return ;
          }
          let linkBasrUrl = process.env.VUE_APP_TEST_LINK_BASE_URL;
          let routeUrl = linkBasrUrl + "/studentStudy/exam/getExamTestInfo?appointmentId="+ row.appointmentId + "&examNo=" + row.examNo;
          //伪造form表单post提交
          var form = document.createElement('form');
          form.action = routeUrl;
          form.target = '_blank';
          form.method = 'POST';
          document.body.appendChild(form);
          form.submit();
          //window.open(routeUrl, '_blank');
      },
      /*中央形考跳入我的学习页面*/
      async goMystudy() {
          this.$router.replace("/student/my_study")
      },
      /*论文跳入我的毕业页面*/
      async goMygraduat() {
          this.$router.replace("/student/my_graduat")
      },
      async goYktLinkUrl() {
          let url = "https://exam.oucnet.com/ks";
          window.open(url, '_blank');
      }
  }



}
</script>
