<!--
 * @Author: xiaziwen
 * @Date: 2021-01-09 11:05:41
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-09 11:12:46
 * @FilePath: \crm-education\src\views\student\my_exam\c\MyExamTimeTable.vue
-->
<template>
  <a-modal
      :visible="recordShow"
      width="800px"
      :maskClosable="false"
      @cancel="toggle(false)"
      :footer="null"
  >
  <div class="student-my-school-role">
    <div class="section-header pt-10 dec d-flex align-center justify-between flex-wrap">
      <h2 class="base-title fs-18">查看通知单</h2>
      <p class="fs-14 color-gray link">
      </p>
    </div>
    <div id="printContent" ref="printContent">
      <h2 style="text-align: center">{{ content.planName}} 通知单</h2>
      <a-card class="cus-card no-border" :bordered="false">
          <div class="d-flex align-center">
            <a-avatar shape="square" :size="100" icon="user" :src="content.studIdImg" />
            <div class="pl-10">
              <div class="fs-12">姓名：{{ content.realName || '暂无数据' }}</div>
              <div class="fs-12">学号：{{ content.studNo || '暂无数据' }}</div>
              <div class="fs-12">身份证号码：{{ content.idNo || '暂无数据' }}</div>
              <div class="fs-12">所在分校：{{ content.organizationName || '暂无数据' }}</div>
              <div class="fs-12">所在考点：{{ content.centreName || '暂无数据' }}</div>
              <div class="fs-12">考点地址：{{ content.centreAddress || '暂无数据' }}</div>
            </div>
          </div>
      </a-card>
      <a-card class="cus-card no-border" :bordered="false">
        <div class="mb-10">
          <examination-table :dataSource="content.detailList"></examination-table>
        </div>
      </a-card>
      <a-card class="cus-card no-border" :bordered="false">
        <div style="background-color: #ffebcd;color: #fb841a">
          请仔细阅读以下几点考试注意事项<br/>
          1. 考试开始30分钟后，考试不得进入考场，请准时参加考试！<br/>
          2. 考生必须带齐准考证、通知单、身份证、学生证参加考试，否则不得进入考场。<br/>
          3. 通知单考生可以自行打印，用A4纸打印即可。<br/>
          4. 如果同一时间有多个科目连考的情况，请考完一科交卷后按科目的考试时间顺序继续进行下一科考试。<br/>
          5. 考生禁止在考场内使用通讯工具，考试期间请服从监考员安排。<br/>
          6. 参加本学期考试必须佩带口罩，如无佩带口罩，不允许进入考场，进入考场请提前实名注册健康码。<br/>
        </div>
      </a-card>
    </div>
    <div style="text-align: center;position: relative;top: 15px;">
      <!--<a-button style="margin-right:10px;" type="primary" size="small" :loading="printing" @click="print">打印准考证</a-button>-->
      <a-button style="margin-right:10px;" type="primary" size="small" :loading="downloading" @click="download(content)">下载通知单</a-button>
    </div>
  </div>
  </a-modal>
</template>

<script>
import ExaminationTable from './ExaminationTable'
import api from '@/api'
import print from 'print-js'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
export default {
  name: 'print_ticket_dialog',
  components: {ExaminationTable  },
  data() {
    return {
      recordShow: false,
      content: {},
      printing: false,
      downloading: false,
    }
  },
  methods: {
      open(data) {
          if (data) {
              this.content = data
          }
          this.toggle()
      },
      toggle(flag = true) {
          this.recordShow = flag
      },
      async print() {
          this.printing = true
           /*//要打印的区域, 调用 print-js 打印
           await print({ printable: 'printContent', type: 'html', targetStyle: ['*'] })
           this.printing = false*/
           //要打印的区域, 先用html2canvas转成图片，在调用 print-js 打印
          /*html2canvas(this.$refs.printContent, {
              backgroundColor: null,
              useCORS: true,//解决图片跨越的问题
              width: 1140,
              height: 1515
          }).then((canvas) => {
              const url = canvas.toDataURL()
              this.img = url
              print({
                  printable: url,
                  type: 'image',
                  documentTitle: '准考证'
              })
          })*/
          var div = document.getElementById('printContent');
          let doc = new jsPDF('p', 'pt', 'a4')
           async function addImage(canvas, doc) {
               const base64Img = canvas.toDataURL('image/jpg')
               //canvas在pdf中图片的宽高
               const imgWidth = 900;
               const imgHeight = 1100;
               //把图片添加到pdf中
               await doc.addImage(base64Img, 'JPEG', 0, 0, imgWidth, imgHeight)
           }
          await html2canvas(this.$refs.printContent,{
              useCORS: true,
              width: 1140,
              height: 1515,
              windowWidth: document.body.scrollWidth,
              windowHeight: document.body.scrollHeight,
              x: 375,
              y: window.pageYOffset
          }).then(canvas => {
              addImage(canvas, doc).then(() => {
                  doc.addPage()
              })
          })
          //获取pdf文件的base64数据
          const PDFbase64 = doc.output('datauristring')
          let arr = PDFbase64.split('base64,')
          let pdf = arr[arr.length - 1]
          await print({ printable: pdf, type: 'pdf', base64: true })
          this.printing = false
      },
      cancel() {
          this.toggle(false)
      },
      async download(row) {
          let planId = row.detailList[0].planId;
          let examMethod = row.detailList[0].examMethod;
          this.downloading = true
          const blob = await api.student.getExamTicketInfoPdf({ planId: planId, examMethod: examMethod})
          console.log(blob)
          this.downloading = false
          let URL = window.URL || window.webkitURL
          console.log(URL)
          let objectUrl = URL.createObjectURL(blob)
          console.log(objectUrl)
          let a = document.createElement('a')
          a.href = objectUrl // 文件流生成的url
          a.download = row.studNo + '（' + row.realName + '）' + '通知单' // 文件名
          document.body.appendChild(a)
          a.click()
          a.remove()
      }
  }



}
</script>
