<!--
 * @Author: xiaziwen
 * @Date: 2021-01-09 10:34:22
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-09 11:32:17
 * @FilePath: \crm-education\src\views\student\my_exam\index.vue
-->
<template>
  <div>
    <div class="mb-10" v-if="isShowSelectCentre&&examPlanInfo.selectState==1">
      <a-card class="exam-time-card no-border" :bordered="false">
        <template slot="title">
          <a-row class="pb-10">
            <a-col :md="{ span: 8 }" :xs="{ span: 24 }">
              <a-select class="cus-select" v-model="planId" @select="planChange" placeholder="选择类别">
                <a-select-option v-for="plan in planList" :value="plan.planId" :key="plan.planId">
                  {{ plan.planName }}
                </a-select-option>
              </a-select>
            </a-col>
          </a-row>
          <div class="section-header pt-10 dec d-flex align-center justify-between flex-wrap">
            <h2 class="base-title fs-18">考点确认</h2>
            <p class="fs-14 color-gray link"></p>
          </div>
          <div style="color: red;position: relative;top: 10px; ">
            <span style="font-size: 14px">注意：</span></br>
            <span style="font-size: 14px">请在{{examPlanInfo.kdEtime}}之前完成考点确认，有问题请联系相关老师。</span>
          </div>
        </template>
        <select-centre :dataSource="selectCentreData" @getAppointment="getAppointment" ></select-centre>
      </a-card>
    </div>

    <div class="mb-10">
      <a-card class="exam-time-card no-border" :bordered="false">
        <template slot="title">
          <a-row class="pb-10" v-if="examPlanInfo.selectState==2">
            <a-col :md="{ span: 8 }" :xs="{ span: 24 }">
              <a-select class="cus-select" v-model="planId" @select="planChange" placeholder="选择类别">
                <a-select-option v-for="plan in planList" :value="plan.planId" :key="plan.planId">
                  {{ plan.planName }}
                </a-select-option>
              </a-select>
            </a-col>
          </a-row>
          <div class="section-header pt-10 dec d-flex align-center justify-between flex-wrap">
            <h2 class="base-title fs-18">考试时间安排</h2>
            <p class="fs-14 color-gray link"></p>
          </div>
        </template>
        <exam-time-table :examPlanInfo="examPlanInfo"></exam-time-table>
      </a-card>
    </div>
    <div class="mb-10">
      <a-card class="exam-time-card no-border" :bordered="false">
        <template slot="title">
          <div class="section-header pt-10 dec d-flex align-center justify-between flex-wrap">
            <h2 class="base-title fs-18">我的考试（{{examAppointmentList.length}}）</h2>
            <p class="fs-14 color-gray link"></p>
          </div>
          <div style="color: red;position: relative;top: 10px;">
            <span style="font-size: 14px">注意：</span></br>
            <span style="font-size: 14px">以下是您本次需要考试的科目，请按照考试时间要求，做好考试准备，准时参加相关考试。</span></br>
            <span style="font-size: 14px">笔试和机考请确认已安排考场和座位号后，再打印通知单。</span>
          </div>
        </template>
        <my-exam-time-table :dataSource="examAppointmentList"></my-exam-time-table>
      </a-card>
    </div>
  </div>
</template>

<script>
// import helper from '@/utils/helper'
import ExamTimeTable from './c/ExamTimeTable'
import MyExamTimeTable from './c/MyExamTimeTable'
import SelectCentre from './c/SelectCentre'
import api from '@/api'
export default {
  name: 'student_my_exam',
  components: {
    ExamTimeTable,
    MyExamTimeTable,
    SelectCentre
  },
  data() {
    return {
      // studId: helper.getUserInfo('userId'),
      planId: '',
      planList: [],
      examPlanInfo: {},
      examAppointmentList: [],
      isShowSelectCentre:false,
      selectCentreData:[]
    }
  },
  mounted() {
      this.init()
  },
  methods: {
    async init() {
      await this.getPlan()
      this.getAppointment()
    },
      /*获取考试计划*/
    async getPlan() {
        let res = await api.student.examPlan({ planId: this.planId })
        if (res.code === 200) {
            this.planList = res.data
            this.planId = res.data[0].planId
            this.matchCurrentPlan(res.data)
        }
    },
      /*当前选择的考试计划*/
    matchCurrentPlan(planList = []) {
        const current = planList.filter(ele => ele.planId === this.planId)
        this.examPlanInfo = current[0] || {}
        this.getAppointment(current.planId)
    },
      /*考试预约列表*/
    async getAppointment() {
        let res = await api.student.appointmentList({ planId: this.planId })
        if (res.code === 200) {
            this.examAppointmentList = res.data
            let newArray = this.examAppointmentList.filter((n)=>{
                if(n.examMethod==2||n.examMethod==3|| n.examMethod == 12 || n.examMethod == 13){
                  return n;
                }
            });
            this.selectCentreData = newArray;
            newArray.sort((a,b)=>{
                let aExamMethod = a.examMethod
                let bExamMethod = b.examMethod
                if(aExamMethod!=bExamMethod){
                    return aExamMethod - bExamMethod
                }else if(a.appointmentId !=b.appointmentId){
                    return a.appointmentId - b.appointmentId
                }
            })
            this.isShowSelectCentre = this.selectCentreData.length>0?true:false;
        }
    },
      /*下拉选择考试计划*/
    planChange() {
        this.matchCurrentPlan(this.planList)
    }
  }
}
</script>

<style lang="scss" scoped>
.exam-time-card {
  ::v-deep .ant-card-body {
    padding-top: 0;
  }
}
</style>
