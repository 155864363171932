<template>
  <a-modal
    title="大作业详情"
    :visible="recordShow"
    okText="确认提交"
    width="800px"
    :maskClosable="false"
    @ok="save"
    @cancel="toggle(false)"
    :footer="null"
  >
    <div class="job-list mb-20">
      <table class="job-info">
        <colgroup>
          <col width="35%" />
          <col width="15%" />
          <col width="15%" />
          <col width="35%" />
        </colgroup>
        <thead>
          <tr>
            <th colspan="5">{{ content.subjectName || '暂无' }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="g-cell">
                <div class="result">
                  <div class="doc" >
                    <a target="_blank" :href="content.courseworkUrl">{{ content.courseworkName }}</a>
                    <a target="_blank" :href="getPdfUrl(content.courseworkUrl)" style="margin-left: 10px"><a-icon type="file-text" /></a>
                  </div>
                </div>
                <div class="txt">作业标题</div>
                <div class="messagetxt">
                  说明：<br/>
                  请下载文件，按照文件要求完成作业
                </div>
              </div>
            </td>
            <td>
              <div class="g-cell">
                <div :class="{
                    result: true,
                    waitSubmit: content.examState === '1',
                    waitCheck: content.examState === '2',
                    error: content.examState === '3',
                    pass: content.examState === '4'
                  }">
                  {{ content.teachPoint || '暂无' }}
                </div>
                <div class="txt">作业得分</div>
              </div>
            </td>
            <td>
              <div class="g-cell">
                <div :class="{
                    result: true,
                    waitSubmit: content.examState === '1',
                    waitCheck: content.examState === '2',
                    error: content.examState === '3',
                    pass: content.examState === '4'
                  }"
                >
                  {{ content.examState | status }}
                </div>
                <div class="txt">作业状态</div>
              </div>
            </td>
            <td>
              <div class="g-cell">
                <div
                  :class="{
                    result: true,
                    waitSubmit: content.examState === '1',
                    waitCheck: content.examState === '2',
                    error: content.examState === '3',
                    pass: content.examState === '4'
                  }"
                >
                </div>
                <div >
                  <a-form-model ref="courseworkUserForm" :model="courseworkUserForm"  v-bind="layout">
                    <a-form-model-item v-if="content.examState && ['2','3'].some(item => content.examState === item )">
                      <div v-for="(item, index) in content.detailList" :key="index">
                        <a target="_blank" :href="item.workUserUrl" class="value">{{ item.workUserName }}</a>
                        <a target="_blank" :href="getPdfUrl(item.workUserUrl)" style="margin-left: 10px" class="value"><a-icon type="file-text" /></a>
                      </div>
                      <div >已上传作业</div>
                    </a-form-model-item>
                    <a-form-model-item v-if="['0','1'].some(item => content.examState === item )">
                      <div v-for="(item, index) in content.detailList" :key="index">
                        <a target="_blank" :href="item.workUserUrl" class="value">{{ item.workUserName }}</a>
                      </div>
                      <a-upload name="file" :multiple="true" :customRequest="handleUpload" :file-list="fileList" :remove="handleDownloadFileRemove">
                        <a-button> <a-icon type="upload" />上传作业</a-button>
                      </a-upload>
                    </a-form-model-item>
                    <a-form-model-item v-if="!content.examState">
                      <a-upload name="file" :multiple="true" :customRequest="handleUpload" :file-list="fileList" :remove="handleDownloadFileRemove">
                        <a-button v-if="content.courseworkUrl"> <a-icon type="upload" />上传作业</a-button>
                      </a-upload>
                      <div class="uploadtxt">注：如果是手写作业，请扫描上传，务必字迹清晰，否则影响教师批改成绩</div>
                    </a-form-model-item>
                  </a-form-model>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div style="text-align: center;position: relative;top: 20px;">
        <a-button v-if="['2','3'].some(item => content.examState === item )" type="primary" @click="cancel">取消</a-button>
        <a-button v-else type="primary" @click="save" :disabled="uoloadLoading">确认提交</a-button>
      </div>
    </div>
  </a-modal>
</template>
<script>
import helper from '@/utils/helper'
import api from '@/api'
export default {
  name: 'coursework_user_dialog',
  data() {
    return {
      recordShow: false,
      content: {},
      fileList: [], //上传后的文件列表
      showUpload: false,
      uoloadLoading: false,
      courseworkUserForm: {
          workUserId: '',
          courseworkId: '',
          subjectId: '',
          appointmentId: '',
          forId: '',
          detailList: []
      },
      layout: {
          labelCol: { span: 6 },
          wrapperCol: { span: 36 }
      },
    }
  },
  filters: {
    status(val) {
      //  作业状态 0未提交 1待批改 2批改不通过 3批改通过
      let text = ''
      switch (val) {
        case '1':
          text = '待批改'
          break
        case '2':
          text = '未通过'
          break
        case '3':
          text = '已通过'
          break
        default :
            text = '未提交'
      }
      return text
    }
  },
  methods: {
    open(data) {
    if (data) {
      this.content = data
      this.courseworkUserForm.courseworkId = data.courseworkId
      this.courseworkUserForm.workUserId = data.workUserId,
      this.courseworkUserForm.subjectId = data.subjectId
      this.courseworkUserForm.appointmentId = data.appointmentId
      this.courseworkUserForm.forId = data.forId
    }
    this.toggle()
    },
    toggle(flag = true) {
      this.recordShow = flag
    },
    save() {
        this.submitForm('courseworkUserForm')
    },
    submitForm(formName) {
        this.$refs[formName].validate(async valid => {
            if (valid) {
                try {

                    if(!this.content.courseworkUrl){
                        this.$message.error('请等待考题上传完毕再提交')
                        return;
                    }

                    if (this.courseworkUserForm.detailList.length <= 0) {
                        this.$message.error('请上传作业')
                        return;
                    }
                    this.uoloadLoading = true
                    await api.student.submitCoursework(this.courseworkUserForm)
                    this.uoloadLoading = false
                    this.cancel()
                    this.$message.info('提交成功')
                } catch (e) {
                    this.uoloadLoading = false
                }
            }
        })
    },
    cancel() {
      this.courseworkUserForm = {
          workUserId: '',
          courseworkId: '',
          subjectId: '',
          appointmentId: '',
          forId: '',
          detailList: []
      }
      this.fileList = []
      this.toggle(false)
    },
    resetForm(formName) {
        this.$refs[formName].resetFields()
    },
      handleSubmit() {
      this.toggle(false)
      this.$emit('submit')
    },
    async handleUpload(file) {
        const formData = new FormData()
        formData.append('file', file.file)
        formData.append('studId', helper.getUserInfo('userId'))
        let res = await api.student.uploadCoursework(formData)
        if (res.code === 200){
            let data = this.fileFormatter(file.file)
            // 上传单个文件后，将该文件回显到已上传文件列表中
            this.fileList.push(data)
            let detail = this.detailFormatter(res.data)
            //把值赋给detailList
            this.courseworkUserForm.detailList.push(detail)
        } else {
            this.$message.error(res.msg)
        }
    },
    detailFormatter(data) {
        let file = {
            workUserUrl: data.url,    // 文件地址
            workUserName: data.fileName,   // 文件名
        }
        return file
    },
    fileFormatter(data) {
        let file = {
            uid: data.uid,    // 文件唯一标识
            name: data.name,   // 文件名
            status: 'done', // 状态有：uploading done error removed
            url: data
        }
        return file
    },
    /*删除文件*/
    handleDownloadFileRemove(file) {
        const index = this.fileList.indexOf(file)
        const newFileList = this.fileList.slice()
        newFileList.splice(index, 1)
        this.fileList = newFileList
    },
    getPdfUrl(url){
      console.log('http://ow365.cn/?i=25157&info=3&furl='+url);
      return 'http://ow365.cn/?info=3&i=25157&furl='+url;
    }
  }
}
</script>
<style lang="scss">
    .job-list {
      .job-info {
        width: 100%;
        border-collapse: collapse;
        thead {
          background: #385dfb;
          color: #fff;
          th {
            font-size: 18px;
            text-align: center;
            height: 50px;
          }
        }
        tbody {
          tr {
            td {
              text-align: center;
              border: 1px solid #d7dcdc;
              padding: 5px 5px;
            }
          }
        }
      }
    }
  .g-course-info {
    text-align: left;
    .info-i {
      padding: 5px 0;
      display: flex;
      justify-content: flex-start;
      .value {
        flex: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
  .g-cell {
    .result {
      font-weight: bold;
      font-size: 20px;
      color: #333;
      margin-bottom: 10px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      &.waitSubmit {
        color: #1890ff;
      }
      &.waitCheck {
        color: #fb841a;
      }
      &.error {
        color: #fb4f27;
      }
      &.pass {
        color: green;
      }
      .doc{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 326px;
        font-size: 14px;
      }
    }
    .uploadtxt{
      text-align: left;
      line-height: 2;
      margin-top: 20px;
      font-size: 8px;
      color: #fb841a;
    }
    .messagetxt{
      position: absolute;
      text-align: left;
      line-height: 2;
      font-size: 8px;
      color: red;
    }
  }
  .g-action {
    .disabled {
      color: #c2bfbf;
    }
  }
</style>
