<!--
 * @Author: xiaziwen
 * @Date: 2021-01-09 10:47:06
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-09 11:02:07
 * @FilePath: \crm-education\src\views\student\my_exam\c\ExamTimeTable.vue
-->
<template>
  <table class="cus-special-table">
    <tr>
      <td class="label">报考时间</td>
      <td class="content">{{ examPlanInfo.bkStime }}——{{ examPlanInfo.bkEtime }}</td>
      <td class="label">预约方式</td>
      <td class="content">{{ examPlanInfo.appointmentType | appointmentTypeName }}</td>
      <td class="label">确认时间</td>
      <td class="content">{{ examPlanInfo.kdStime }}——{{ examPlanInfo.kdEtime }}</td>
    </tr>
    <tr>
      <td class="label">形考课程学习截止时间</td>
      <td class="content">{{ examPlanInfo.xkEtime }}</td>
      <td class="label">成绩上报截止时间</td>
      <td class="content" colspan="3">{{ examPlanInfo.sbTime }}</td>
    </tr>
    <tr>
      <td  colspan="6">
        <div style="color: red">
          <span>注意：</span></br>
          <span>1、学校为学生预约考试后，学生必须在确认时间范围内登录平台确认是否报考，若不想参与考试，请主动取消。若超时未确认，自动默认为确认报考，请做好考试准备。</span></br>
          <span>2、学生务必要在中央形考课程学习截止时间前，根据课程的网络说明完成相应课程的学习任务，若超时未完成，将会影响此门课程的通过率。</span></br>
          <span>3、学校会在成绩上报截止时间后导出本学期的学习成绩和考试成绩，上报至国开系统，届时将关闭本学期所有课程的学习考试通道，为了避免影响课程考核通过率，请务必按时完成课程学习和考试。</span></br>
        </div>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: 'my_exam_time_table',
  props: {
    examPlanInfo: {
      type: Object
    }
  },
  filters: {
      appointmentTypeName(val) {
          let text = ''
          switch (val) {
              case '1':
                  text = '集中报考'
                  break
              case '2':
                  text = '个人报考'
                  break
              default :
                  text = ''
          }
          return text
      }
  }

}
</script>

<style lang="scss" scoped>
.cus-special-table {
  border-collapse: collapse;
  width: 100%;
  td {
    border: 1px solid #d7dcdc;
    padding: 15px 10px;
    &.label {
      background-color: #eff2fb;
    }
  }
}
</style>
